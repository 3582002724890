import * as React from "react"
import Hero from "../roaster/hero"
import Join from "../roaster/join"
import pricecrises from "../../assets/images/pricecrises.png"

const PriceCrises = ({ pageContext: { translate } }) => {
  return (
    <>
      {
        <div className="PriceCrises">
          <div className="roaster_page">
            <div className="content">
              <Hero
                pageContext={{ translate }}
                pagename="Era of We"
                title={translate.t?.new_optimistic_capitalism}
                description={translate.t?.a_digital_platform_establish}
                img={pricecrises}
              />
            </div>
          </div>
          <div className="description">
            <p className="paragraph-p1 text-darker">
              {translate.t?.price_crisis_1}
              <br />
              <br />
              {translate.t?.price_crisis_2}
              <br />
              <br />
              {translate.t?.price_crisis_3}
              <br />
              <br />
              {translate.t?.price_crisis_4}
              <br />
              <br />
              {translate.t?.price_crisis_5}
              <br />
              <br />
              {translate.t?.price_crisis_6}
              <br />
              <br />
              {translate.t?.price_crisis_7}
            </p>
          </div>
          <div className="joiner-second">
            <Join pageContext={{ translate }} />
          </div>
        </div>
      }
    </>
  )
}

export default PriceCrises
